<template>
  <!-- <dv-loading v-if="!totalData">加载中...</dv-loading> -->
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="!totalData">加载中...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center">
          <dv-decoration-10 class="dv-dec-10" />
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="decorationColor" />
            <div class="title">
              <span class="title-text" @click="modal = true">浔食安“综合查一次”监管平台</span>
              <dv-decoration-1 class="dv-dec-6" :reverse="true" :color="['#50e3c2', '#67a1e5']" />
            </div>
            <dv-decoration-8 class="dv-dec-8" :reverse="true" :color="decorationColor" />
          </div>
          <dv-decoration-10 class="dv-dec-10-s" />
        </div>

        <!-- 第二行 -->
        <div class="d-flex jc-between px-2">
          <div class="d-flex aside-width">
            <div class="react-left ml-7 react-l-s">
              <img src="../assets/back.png" class="react-left"
                style="width: 50px;height: 50px;top: -43px;cursor: pointer;left: 39px;" @click="go">
              <span class="text">{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span>
            </div>

          </div>
          <div class="d-flex aside-width">
            <div class="react-right bg-color-blue mr-7">
              <span class="text fw-b">最近一次数据更新时间：{{ dateYear1 }} {{ dateWeek1 }} {{ dateDay1 }}</span>
            </div>
          </div>
        </div>

        <div class="body-box">
          <!-- 第三行数据 -->
          <div class="content-box">
            <div class="top">
              <dv-border-box-8>
                <div class="titles">
                  <img src="../assets/3.png" />
                  <span>市场监管</span>
                  <dv-decoration-1 style="width:60%" class="dv-dec-1" />
                </div>
                <div class="top-1">
                  <div class="left">
                    <dv-border-box-13 v-if="totalData">检查家次：<b>{{ totalData.checkCount
                        }}</b></dv-border-box-13>

                  </div>
                  <div class="left">
                    <dv-border-box-13 v-if="totalData">整改率：<b>{{ totalData.abarbeitungRate
                        }}%</b></dv-border-box-13>

                  </div>
                </div>

                <div class="top-2">

                  <dv-capsule-chart v-if="totalData && !isLoading" :config="config" style="width:45%;height:130px" />
                  <div class="circle" style="width:35%">
                    <div style="   width:100%; text-align: center;padding-top: 11px;">发现隐患统计</div>
                    <dv-active-ring-chart v-if="totalData && config2Value !== 0" :config="config2"
                      style="width:100%;height:170px;margin-top: -22px;" />
                    <dv-active-ring-chart v-else :config="config4Empty"
                      style="width:100%;height:170px;margin-top: -22px;" />
                  </div>
                  <div class="points" v-if="totalData">
                    <div class="points1" v-for="(item, index) in config2.data" :key="index">
                      <div class="radius" :style="'background:' + color[index]"></div>
                      <span>{{ item.name }}</span>
                    </div>
                  </div>
                </div>
                <div class="top2-tit">
                  <dv-decoration-2 style="width:200px;height:5px;margin-top: -10px;" />
                  <p class="info">许可证状态</p>
                </div>
                <div class="top-3">
                  <dv-active-ring-chart v-if="totalData && config4Value !== 0" :config="config4"
                    style="width: 40%;height: 222px;margin-top: -35px;" />
                  <dv-active-ring-chart v-else :config="config4Empty"
                    style="width: 40%;height: 222px;margin-top: -35px;" />
                  <div class="points">
                    <div class="points1" v-for="(item, index) in config4.data" :key="index">
                      <div class="radius" :style="'background:' + color[index]"></div>
                      <span>{{ item.name }}</span>
                    </div>
                  </div>
                </div>
              </dv-border-box-8>
            </div>
            <div class="bottom">
              <dv-border-box-8>
                <div class="titles">
                  <img src="../assets/4.png" />
                  <span>应急消防</span>
                  <dv-decoration-1 style="width:60%" class="dv-dec-1" />
                </div>
                <div class="hot">
                  <div class="hot-1">
                    <dv-border-box-1>
                      <div class="hot-1-1">
                        <dv-digital-flop class="dv-digital-flop" style="width:40%;" :config="config13" />
                        <p class="hot-1-2">较昨日：{{ totalData.yesterdayArbeitungCount }}</p>
                        <div class="hot-1-3">发现隐患总数</div>
                      </div>
                    </dv-border-box-1>
                  </div>
                  <div class="hot-1 hot-2">
                    <dv-border-box-1>
                      <div class="hot-1-1">
                        <dv-digital-flop class="dv-digital-flop" style="width:40%;" :config="config3" />
                        <p class="hot-1-2">较上月：{{ totalData.lastMonthabArbeitungRate }}%</p>
                        <div class="hot-1-3">隐患整改率</div>
                      </div>
                    </dv-border-box-1>
                  </div>
                </div>
                <div class="pond">
                  <span>消防设备完好率</span>
                  <dv-percent-pond v-if="totalData && !isLoading" :config="config8" style="width:200px;height:80px;" />
                </div>

                <div class="list">
                  <div class="list-1 list-3">
                    <dv-border-box-12>
                      <div v-if="totalData">
                        <p>灭火器配备情况</p>
                        <p>灭火器显示异常</p>
                        <p>待整改单位数：{{ totalData.extinguisherAbnormityCount }}</p>
                        <p>未按要求配备灭火器</p>
                        <p>待整改单位数：{{ totalData.extinguisherEquipCount }}</p>
                        <p>是否按要求检查登记</p>
                        <p>待整改单位数：{{ totalData.extinguisherCheckInCount }}</p>
                      </div>
                    </dv-border-box-12>
                  </div>
                  <div class="list-1 list-2">
                    <dv-border-box-12>
                      <div v-if="totalData">
                        <p>其他隐患</p>
                        <p>电器线路私拉乱扯,未穿保护管套</p>
                        <p>待整改单位数：{{ totalData.otherElectricalCircuitCount }}</p>
                        <p>存储燃气瓶数量是否超标</p>
                        <p>待整改单位数：{{ totalData.otherGasCylinderExceedingCount }}</p>
                        <p>电瓶车是否有在店内停放,充电</p>
                        <p>待整改单位数：{{ totalData.otherBatteryCarParkingCount }}</p>
                      </div>
                    </dv-border-box-12>
                  </div>
                </div>
              </dv-border-box-8>


            </div>
          </div>
          <div class="content-box">
            <div>
              <dv-border-box-8>
                <el-select style="margin-top:10px;margin-left:10px" placeholder="请选择" v-model='regionValue'
                  @change="regionChange">
                  <el-option v-for="item in streetAry" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select>
                <el-date-picker style="margin-top:8px;margin-left:10px" type="daterange" v-model="timePicker"
                  @change="timeChange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>

                <div class="map" id="map" v-if="regionValue && regionValue !== '全部'">
                </div>
                <centerLeft2 v-else @getRegion="regionChange" />
              </dv-border-box-8>
            </div>

            <div>
              <dv-border-box-8>
                <div class="center-bottom">
                  <div class="top2-tit">
                    <img src="../assets/1.png" />
                    <span class="info">健康证情况</span>
                    <dv-decoration-1 style="width:60%" class="dv-dec-1" />
                  </div>
                  <div class="top-3" style="margin-top:-90px">

                    <dv-active-ring-chart v-if="totalData && config5Value !== 0" :config="config5"
                      style="width:40%;height:260px" />
                    <dv-active-ring-chart v-else :config="config4Empty" style="width:40%;height:260px" />
                    <div class="points">
                      <div class="points1" v-for="(item, index) in config5.data" :key="index">
                        <div class="radius" :style="'background:' + color[index]"></div>
                        <span>{{ item.name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </dv-border-box-8>
            </div>
          </div>

          <!-- 右 -->
          <div class="content-box">
            <div class="top-4">
              <dv-border-box-8>
                <div class="center-bottom">
                  <div class="top2-tit">
                    <img src="../assets/2.png" />
                    <span class="info">公安</span>
                    <dv-decoration-1 style="width:60%" class="dv-dec-1" />
                  </div>
                  <div class="top-1">
                    <div class="left">
                      <p>整体员工总数：</p>
                      <dv-digital-flop v-if='totalData' class="dv-digital-flop" style="width:30%;font-size: 20px;"
                        :config="config10" />

                    </div>
                    <div class="left">
                      <p>流动人口总数：</p>
                      <dv-digital-flop v-if='totalData' class="dv-digital-flop" style="width:30%;" :config="config11" />


                    </div>
                    <div class="left">
                      <p>发现违法犯罪行为数：</p>
                      <dv-digital-flop v-if='totalData' class="dv-digital-flop" style="width:30%;" :config="config12" />
                    </div>
                  </div>
                  <div class='bottom-tit'>
                    <div class="top3-tit width">
                      <p class="info">流动人口分布</p>

                      <div class="top-3">
                        <dv-active-ring-chart v-if='totalData && config6Value !== 0 && !isLoading' :config="config6"
                          style="width:100%;height:260px" />
                        <dv-active-ring-chart v-if="config6Value === 0 && !isLoading" :config="config4Empty"
                          style="width:100%;height:260px" />
                      </div>
                      <div class="points points2">
                        <div class="points1 " v-for="(item, index) in config6.data" :key="index">
                          <div class="radius" :style="'background:' + color[index]"></div>
                          <span>{{ item.name }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="top3-tit2 width">
                      <p class="info">从业人员年龄分布</p>

                      <div class="top-3">
                        <dv-active-ring-chart v-if='totalData && config7Value !== 0 && !isLoading' :config="config7"
                          style="width:100%;height:260px" />
                        <dv-active-ring-chart v-if="config7Value === 0 && !isLoading" :config="config4Empty"
                          style="width:100%;height:260px" />
                      </div>
                      <div class="points points2">
                        <div class="points1" v-for="(item, index) in config7.data" :key="index">
                          <div class="radius" :style="'background:' + color[index]">
                          </div>
                          <span>{{ item.name }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </dv-border-box-8>
            </div>
            <div class="top-4">
              <dv-border-box-8>
                <div class="center-bottom">
                  <div class="top2-tit">
                    <img src="../assets/5.png" />
                    <span class="info">综合执法</span>
                    <dv-decoration-1 style="width:60%" class="dv-dec-1" />
                  </div>
                  <div class="pond pond2">
                    <span>综合执法项目检查合格率</span>
                    <dv-percent-pond v-if="totalData  && !isLoading " :config="config9"
                      style="width:200px;height:80px;" />
                  </div>
                  <div class="list list2">
                    <!-- <dv-decoration-1 style="width:200px;height:50px;" /> -->
                    <div class="list-1 list-4" style="width:80%">
                      <dv-border-box-1>
                        <div v-if="totalData">
                          <p>垃圾分类未落实</p>
                          <p>待整改单位：{{ totalData.synthesisPlaceRefuseSortingCount }}</p>
                          <p>油烟净化器未按要求配备或缺失</p>
                          <p>待整改单位：{{ totalData.synthesisFumeEvolutorCount }}</p>
                          <p>三级隔油池未正常使用</p>
                          <p>待整改单位：{{ totalData.synthesisThreeStageGreaseTrapCount }}</p>
                          <p>有出摊经营行为</p>
                          <p>待整改单位：{{ totalData.synthesisShareOperationCount }}</p>
                        </div>
                      </dv-border-box-1>
                    </div>
                  </div>
                </div>
              </dv-border-box-8>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div clas="modals" v-if="modal">
      <modal @closeModal="closeModal" :checkDetails="checkDetails" :fellowPersonnelList="fellowPersonnelList" />
    </div>
    <div class="modal" v-if="shopImg">
      <img src="../assets/close.png" class="close" @click="shopImg = null">
      <img :src="shopImg" class="shopImg" />
      <div class="opacity"></div>
    </div>
  </div>
</template>

<script>
  import drawMixin from "../utils/drawMixin";
  import { formatTime } from '../utils/index.js'
  import centerLeft2 from './centerLeft2'
  import modal from './modal'
  import * as echarts from 'echarts';
  import AMapLoader from '@amap/amap-jsapi-loader';
  import { shopList, shopCheck, audit, shopChecks } from "@/api/login";
  export default {
    mixins: [drawMixin],
    data() {
      return {
        shopImg: null,
        fellowPersonnelList: [],
        regionValue: '全部',
        timePicker: [],
        totalData: null,
        checkDetails: {},
        streetAry: ['全部', '东迁街道', '南浔镇', '练市镇', '双林镇', '菱湖镇', '和孚镇', '善琏镇', '石淙镇', '千金镇', '旧馆街道'],
        details: {},
        doorsList: [],
        map: null,
        modal: false,
        config8: {},
        config9: {},
        color: ['#8ABDF3', '#4367DC', '#54DED7', '#EEE397', '#FAAD14', '#4FC1C9', '#546BFF'],
        config13: {
          number: [],
          content: '{nt}'
        },
        config3: {
          number: [],
          content: '{nt}%'
        },
        config1: {
          number: [],
          content: '{nt}'
        },
        config10: {
          number: [],
          content: '{nt}'
        },
        config11: {
          number: [],
          content: '{nt}'
        },
        config12: {
          number: [],
          content: '{nt}'
        },
        config7: {
          digitalFlopStyle: {
            fontSize: 15,
            fill: '#fff'
          },
          lineWidth: 15
        },
        config6: {
          digitalFlopStyle: {
            fontSize: 15,
            fill: '#fff'
          },
          lineWidth: 15
        },
        config5: {
          digitalFlopStyle: {
            fontSize: 22,
            fill: '#fff'
          },
          lineWidth: 15,
          showOriginValue: true
        },
        config4: {
          digitalFlopStyle: {
            fontSize: 14,
            fill: '#fff'
          },
          lineWidth: 15,
          showOriginValue: true
        },
        config2: {
          digitalFlopStyle: {
            fontSize: 14,
            fill: '#fff'
          },
          lineWidth: 15,
          showOriginValue: true
        },
        config4Empty: {
          digitalFlopStyle: {
            fontSize: 0,
            fill: '#fff'
          },
          lineWidth: 15,
          showOriginValue: true,
          data: [{
            name: '',
            value: 1
          }],
          color: ['#dddddd']
        },
        timing: null,
        loading: true,
        dateDay: null,
        dateYear: null,
        dateWeek: null,
        dateDay1: null,
        dateYear1: null,
        dateWeek1: null,
        weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
        decorationColor: ['#568aea', '#000000'],
        config: {
          data: [
            {
              name: '合格数'
            },
            {
              name: '隐患数'
            },
            {
              name: '整改数'
            }
          ],
          colors: ['#1B7EF2', '#BD9000', '#58A600'],
          showValue: true
        },
        mapCenter: null,
        isLoading: false,
        times: null
      }
    },
    components: {
      centerLeft2,
      modal
    },
    mounted() {
      this.timeFn()
      this.cancelLoading()

    },
    created() {
      this.initAMap()
      // this.shopList('双林镇')
      const val = this.timePicker
      this.audit(this.regionValue === '全部' ? '' : this.regionValue, (val && val.length) ? this.timePicker[0] : '', (val && val.length) ? this.timePicker[1].split(' ')[0] + ' 23:59:59' : '')
      this.times = setInterval(() => {
        this.audit(this.regionValue === '全部' ? '' : this.regionValue, (val && val.length) ? this.timePicker[0] : '', (val && val.length) ? this.timePicker[1].split(' ')[0] + ' 23:59:59' : '')
      }, 60 * 5 * 1000)
    },
    beforeDestroy() {
      clearInterval(this.timing)
      clearInterval(this.times)
    },
    methods: {
      returnCenter(name) {
        var center
        if (name === '南浔镇') center = { lng: 120.431469, lat: 30.872505 }
        else if (name === '练市镇') center = { lng: 120.429009, lat: 30.712698 }
        else if (name === '东迁街道') center = { lng: 120.426852, lat: 30.872926 }
        if (name === '双林镇') center = { lng: 120.336816, lat: 30.778398 }
        else if (name === '和孚镇') center = { lng: 120.16947, lat: 30.783195 }
        else if (name === '旧馆街道') center = { lng: 120.28861, lat: 30.841296 }
        if (name === '石淙镇') center = { lng: 120.273657, lat: 30.726523 }
        else if (name === '善琏镇') center = { lng: 120.324178, lat: 30.705508 }
        else if (name === '千金镇') center = { lng: 120.248064, lat: 30.682385 }
        if (name === '菱湖镇') center = { lng: 120.190923, lat: 30.718937 }
        return center
      },
      timeChange(val) {
        if (val && val.length) {
          this.audit(this.regionValue === '全部' ? '' : this.regionValue, val[0], val[1].split(' ')[0] + ' 23:59:59')
        }
      },
      regionChange(vals) {
        this.totalData = {}
        this.doorsList = []
        this.regionValue = vals.name ? vals.name : vals
        this.mapCenter = vals.center ? vals.center : this.returnCenter(vals)
        const val = this.timePicker
        this.shopList(this.regionValue === '全部' ? '' : this.regionValue, (val && val.length) ? this.timePicker[0] : '', (val && val.length) ? this.timePicker[1].split(' ')[0] + ' 23:59:59' : '')
        this.audit(this.regionValue === '全部' ? '' : this.regionValue, (val && val.length) ? this.timePicker[0] : '', (val && val.length) ? this.timePicker[1].split(' ')[0] + ' 23:59:59' : '')
      },
      shopList(val) {
        shopList(val).then(res => {
          this.doorsList = res.data
          this.initAMap()
        })
      },
      shopCheck(shopId, cb) {
        shopCheck(shopId).then(res => {
          this.checkDetails = res.data
          this.fellowPersonnelList = res.data.fellowPersonnelList.map(item => {
            return item.nickName
          })
          cb()
        })
      },
      isFalse(data, cb) {
        var value = 0
        for (let i = 0; i < data.length; i++) {
          value += data[i].value
        }
        if (value === 0) {
          data = data.map(item => {
            item.value = 1
            return item
          })
          cb(data)
        }

      },
      go() {
        window.history.go(-1)
      },
      // 获取综合检查
      audit(val, start, end) {
        this.isLoading = true
        this.dateDay1 = formatTime(new Date(), 'HH: mm: ss')
        this.dateYear1 = formatTime(new Date(), 'yyyy-MM-dd')
        this.dateWeek1 = this.weekday[new Date().getDay()]
        this.config6Value = 0
        this.config2Value = 0
        this.config5Value = 0
        this.config4Value = 0
        this.config7Value = 0
        audit(val, start, end).then(res => {
          this.isLoading = false
          this.totalData = res.data
          this.config1.number = [res.data.checkCount ? res.data.checkCount : 0]
          this.config13.number = [res.data.fireFightingCount ? res.data.fireFightingCount : 0]
          this.config3.number = [res.data.fireAbarbeitungRate ? Number(res.data.fireAbarbeitungRate) : 0]
          this.config.data[0].value = res.data.eligibleCount ? res.data.eligibleCount : 0
          this.config.data[1].value = res.data.hiddenDangerCount ? res.data.hiddenDangerCount : 0
          this.config.data[2].value = res.data.abarbeitungCount ? res.data.abarbeitungCount : 0
          this.config6.data = res.data.transientPopulationList.map((item) => {
            this.config6Value = this.config6Value + Number(item.value)
            return {
              name: item.type,
              value: item.value
            }
          })
          // this.isFalse(this.config6.data, (data) => { this.config6.data = data })
          this.config2.data = res.data.hiddenDangerList.map((item) => {
            this.config2Value = this.config2Value + Number(item.value)
            return {
              name: item.type,
              value: item.value
            }
          })
          // this.isFalse(this.config2.data, (data) => { this.config2.data = data })
          this.config4.data = res.data.licenseList.map((item) => {
            this.config4Value = this.config4Value + Number(item.value)
            return {
              name: item.type,
              value: item.value
            }
          })
          // this.isFalse(this.config4.data, (data) => { this.config4.data = data })
          this.config5.data = res.data.healthCertificatesList.map((item) => {
            this.config5Value = this.config5Value + Number(item.value)
            return {
              name: item.type,
              value: item.value
            }
          })
          // this.isFalse(this.config5.data, (data) => { this.config5.data = data })
          this.config7.data = res.data.employeeAgeList.map((item) => {
            this.config7Value = this.config7Value + Number(item.value)
            return {
              name: item.type,
              value: item.value
            }
          })
          // this.isFalse(this.config7.data, (data) => { this.config7.data = data })
          this.config8.value = res.data.fireFightingRate
          this.config9.value = res.data.enforcementCheckRate
          this.config10.number = [res.data.employeeCount ? res.data.employeeCount : 0]
          this.config11.number = [res.data.transientPopulationCount ? res.data.transientPopulationCount : 0]
          this.config12.number = [res.data.crimeActivityCount ? res.data.crimeActivityCount : 0]
        })
      },
      initAMap() {
        var markerList = []
        AMapLoader.load({
          key: 'ac02bea89e01b580212ded3b62b43682',  //设置您的key
          version: "2.0",
          plugins: ['AMap.ToolBar', 'AMap.Driving'],
          AMapUI: {
            version: "1.1",
            plugins: [],

          },
          Loca: {
            version: "2.0"
          },
        }).then((AMap) => {
          var infoWindow = new AMap.InfoWindow({
            isCustom: true,  //使用自定义窗体
            content: '',
            offset: new AMap.Pixel(120, -30)
          });
          for (let i = 0; i < this.doorsList.length; i++) {
            const item = this.doorsList[i]
            // if (item.longitude) {
            const marker = new AMap.Marker({
              position: new AMap.LngLat(Number(item.longitude), Number(item.latitude))
            });
            marker.content = []
            marker.content.push("<div style='margin-bottom:-10px;opacity:1;width: 229px;height: 112px;padding:10px;background: #092042 linear-gradient(225deg, #092348 0%, #0B3E79 100%); '><div style='display: flex;align-items:center;'><img onclick='imgFun(" + i + ")' src='" + item.shopImg + "' style='cursor: pointer;width:50px;height:50px;margin-right:20px;'/><b style='color:#00BAFF;font-size:16px;margin-top:-20px;'>" + item.name + "</b></div><p onclick='handleBreak(" + i + ")' style='cursor:pointer;margin-top:20px;font-size:12px;border-radius:5px;text-align:center;line-height:26px;width: 68px;height: 26px;color: #0B3C76;background: #D8D8D8 linear-gradient(270deg, #00BAFF 0%, #00FFFC 100%); '>查看检查</div>");
            // marker.content.push("<div style='width:14px;height:24px;border-right:2px solid #0883FD;transform:rotate(-158deg)'></div>")
            marker.on('click', (e) => {
              infoWindow.open(window.map, marker.getPosition());
              infoWindow.setContent(e.target.content);
            });
            marker.emit('click', { target: marker });
            //实例化信息窗体
            window.handleBreak = (i) => {
              shopChecks(this.doorsList[i].id).then(res => {
                this.checkDetails = res.data
                this.fellowPersonnelList = res.data.fellowPersonnelList.map(item => {
                  return item.nickName
                })
                this.modal1 = true
                this.$forceUpdate()
              })

            }
            window.imgFun = (i) => {
              this.shopImg = this.doorsList[i].shopImg
            }
            markerList.push(marker)
            // }


          }

          // for (let i = 0; i < this.doorsList.length; i++) {
          //     const item = this.doorsList[i]
          window.map = new AMap.Map("map", {
            mapStyle: 'amap://styles/darkblue', //设置地图的显示样式
            viewMode: "3D",
            zoom: 17,
            center: [Number(this.mapCenter.lng), Number(this.mapCenter.lat)]
          });
          window.map.add(markerList)
          window.map.setFitView();

        }).catch(() => {
        })
      },
      // 关闭弹窗
      closeModal() {
        this.modal = false
      },
      chart1() {
        let option = {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            top: '5%',
            left: 'center'
          },
          series: [
            {
              name: 'Access From',
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
              },
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 40,
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              data: [
                { value: 1048, name: 'Search Engine' },
                { value: 735, name: 'Direct' },
                { value: 580, name: 'Email' },
                { value: 484, name: 'Union Ads' },
                { value: 300, name: 'Video Ads' }
              ]
            }
          ]
        };
        // 初始化echart
        this.chart = echarts.init(document.getElementById('chart1'))
        this.chart.setOption(option, true)
      },
      timeFn() {
        this.timing = setInterval(() => {
          this.dateDay = formatTime(new Date(), 'HH: mm: ss')
          this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
          this.dateWeek = this.weekday[new Date().getDay()]
        }, 1000)
        // this.chart1()
      },
      cancelLoading() {
        setTimeout(() => {
          this.loading = false
        }, 500)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../assets/scss/index.scss';

  .opacity {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.8;
    z-index: 1;
  }

  .close {
    position: fixed;
    right: 50%;
    width: calc(100vw * 50 / 1920);
    height: auto;
    z-index: 557777666667;
    cursor: pointer;
    top: 28%;
    margin-right: -250px;
  }

  .shopImg {
    position: fixed;
    top: 30%;
    left: 50%;
    width: 400px;
    margin-left: -200px;
    z-index: 2;
  }

  #map {
    height: 680px;
  }

  ::v-deep .el-select .el-input__inner:focus {
    background: none;
    color: #fff;
    margin-right: 10px;
  }

  ::v-deep .el-input--medium .el-input__inner,
  ::v-deep .el-range-editor--medium.el-input__inner,
  ::v-deep .el-range-editor--medium .el-range-input,
  ::v-deep .el-range-editor--medium .el-range-separator {
    background: 0;
    color: #fff;
  }

  .top,
  .bottom {
    height: 470px;

    .titles {
      font-size: 22px;
      display: flex;
      align-items: center;
      font-weight: bold;

      img {
        width: 80px;
        margin: 5px 10px;
      }
    }

    .top-1 {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        width: 50%;
        height: 80px;
        line-height: 80px;
        text-align: center;

        b {
          font-size: 30px;
        }
      }
    }

    .top-2 {
      display: flex;
      align-items: center;
      justify-content: center;

      .chart1 {
        width: 50%;
        height: 130px;
      }
    }

    .info {
      padding-top: 10px;
      margin-left: 10px;
      font-size: 22px;
      font-weight: bold;
    }

    .points1 {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #fff;
      margin-bottom: 8px;

      .radius {
        width: 8px;
        height: 8px;
        background: red;
        border-radius: 50%;
        margin-right: 5px;
      }
    }

    .top-3 {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -40px;


    }

  }

  .bottom img {
    width: 80px;
    margin: 5px 10px;
  }

  .top-4 {
    height: 469px;
  }

  .center-bottom {
    height: 222px;

    .top-3 {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -80px;
    }

    .top2-tit {
      font-size: 22px;
      display: flex;
      font-weight: bold;
      align-items: center;

      img {
        width: 80px;
        margin: 5px 10px;
      }
    }

    .info {
      padding-top: 10px;
      margin-left: 10px;
      font-size: 22px;
      font-weight: bold;
    }

    .points1 {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #fff;
      margin-bottom: 8px;

      .radius {
        width: 8px;
        height: 8px;
        background: red;
        border-radius: 50%;
        margin-right: 5px;
      }
    }

    .top-1 {
      margin: 20px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        margin-right: 10px;
        font-weight: bold;
      }
    }

    .bottom-tit {
      display: flex;
      justify-content: space-between;
    }

    .top3-tit2 {
      margin-right: 20px;
    }

    .width {
      width: 50%;
      text-align: center;
    }

    .top-3 {
      margin-top: -40px;
    }
  }

  .dv-dec-1 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }

  .points2 {
    margin-top: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .points1 {
      margin-right: 5px;
      width: 40%;
    }
  }

  .hot {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1%;

    .hot-1 {
      width: 49%;
      height: 100px;

      .hot-1-1 {
        padding-top: 10px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .hot-1-3 {
          position: absolute;
          width: 100%;
          text-align: center;
          bottom: 20px;
          font-size: 19px;
        }

        .hot-1-2 {
          font-size: 19px;
        }
      }
    }
  }

  .pond {
    width: 100%;
    height: 90px;
    margin-top: 10px;
    display: flex;
    align-items: center;

    // justify-content: center;
    span {
      font-size: 18px;
      margin-right: 20px;
      margin-left: 10px;
    }
  }

  .pond2 {
    height: 160px;
  }

  .list {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.5%;
    margin-top: 0px;

    .list-1 {
      width: 49%;
      height: 180px;

      div {
        padding: 5px;

        p {
          text-align: left;
          font-size: 18px;
          color: #fff;
          line-height: 1.3;
        }
      }

    }

    .list-2 {
      width: 60%
    }

    .list-3 {
      width: 39%;
    }

    .list-4 {
      width: 60%;
      height: 260px;
      margin-top: -30px;

      div {
        padding-left: 50px;
        padding-top: 20px;
        line-height: 2;
      }
    }
  }

  .list2 {
    height: 210px;
    justify-content: center;

    .list-1 {
      width: 60%;
    }
  }
</style>